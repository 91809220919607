import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactEcharts from 'echarts-for-react';
import { DateTime } from 'luxon';
import { adjustPrecision, fillByGranularity } from './utils';

class YearUsageHeatmap extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // option: this.getOption()
        }
    }

    getOption(props) {
        const { day, date } = props;

        if (!day) {
            return {}
        }

        const year_data = fillByGranularity(day, date, 'day')
            .filter(d => DateTime.fromJSDate(new Date(d.name)).hasSame(date, 'year'))
            .map(d => ({ name: d.name, value: adjustPrecision(d.value, 1, 1) }));

        if (!year_data || year_data.length === 0) {
            return {}
        }

        const year_data_usage = year_data
            .map(d => ({ name: d.name, value: [d.name, d.value.Usage ? d.value.Usage : undefined] }))
            .map(d => DateTime.fromJSDate(new Date(d.name)).hasSame(date, 'day')
                ? Object.assign({}, d, { itemStyle: { borderWidth: 2, borderColor: '#0d47a1' } })
                : d
            )
        const year_data_usage_max = Math.max(...(year_data_usage.map(d => d.value[1] ? d.value[1] : 0)))

        // console.log('year_data_usage:', year_data_usage);
        // console.log('year_data_usage_max:', year_data_usage_max);

        const locale = window.navigator.languages.includes('zh') ? 'cn' : 'en';
        // console.log('browser locale:', locale);

        var option = {
            animation: false,
            tooltip: {
                position: 'top',
                formatter: (params) => (params && params.data && params.data.value && params.data.value[1])
                    ? DateTime.fromJSDate(new Date(params.data.value[0])).toLocaleString(DateTime.DATE_FULL) + '<br/> <b>' + parseFloat(params.data.value[1]).toFixed(1) + ' kWh</b>'
                    : ''
            },
            visualMap: {
                show: false,
                min: 0,
                max: year_data_usage_max,
                orient: 'horizontal',
                left: 'center',
                type: 'continuous',
                // inRange: { color: ['#f6efa6', '#d88273', '#bf444c'] }
                // inRange: { color: ['#e3f2fd', '#0d47a1', '#4a148c'] }
            },
            calendar: {
                top: '20%',
                left: '25pt',
                right: '20pt',
                height: '50%',
                range: date.getFullYear(),
                itemStyle: {
                    color: '#fff6',
                    borderWidth: 0.5
                },
                splitLine: {
                    lineStyle: {
                        color: '#999',
                        // width: 0.5,
                    }
                },
                dayLabel: { nameMap: locale, color: '#ccc', firstDay: 1, fontSize: '0.7em' },
                monthLabel: { nameMap: locale, color: '#ccc', fontSize: '0.7em' },
                yearLabel: { show: false }
            },
            series: [{
                type: 'heatmap',
                coordinateSystem: 'calendar',
                emphasis: {
                    // label: {
                    //     show: true
                    // },
                    itemStyle: {
                        shadowBlur: 10,
                    }
                },
                data: year_data_usage,
            }]
        };

        return option
    }

    render() {
        const option = this.getOption(this.props);
        const { onChangeDate } = this.props;

        return (
            <ReactEcharts
                option={option}
                style={{ height: '20vmax' }}
                onEvents={{ 'click': d => { onChangeDate(new Date(d.value[0])); } }}
            />
        );
    }
}

YearUsageHeatmap.propTypes = {
    day: PropTypes.array,
    date: PropTypes.instanceOf(Date),
    onChangeDate: PropTypes.func,
};

YearUsageHeatmap.defaultProps = {
    day: [],
    date: Date.now(),
    onChangeDate: () => { },
}

export default YearUsageHeatmap;
