import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactEcharts from 'echarts-for-react';
import { DateTime } from 'luxon';

import { fillByGranularity, adjustPrecision } from './utils';

class YearUsage extends Component {
    constructor(props) {
        super(props);
        this.state = {}
        this.option = this.getOption(props)
    }

    getOption(props) {
        const { month, date } = props;

        if (!month) {
            return {}
        }

        const year_data = fillByGranularity(month, date, 'month')
            .filter(d => DateTime.fromJSDate(new Date(d.name)).hasSame(date, 'year'))
            .map(d => ({ name: d.name, value: adjustPrecision(d.value, 1, 1) }))
        if (!year_data || year_data.length === 0) {
            return {}
        }
        // console.log('year_data:', year_data);

        const year_data_usage = year_data
            .map(d => ({ name: d.name, value: d.value.Usage }))
            .map(d => DateTime.fromJSDate(new Date(d.name)).hasSame(date, 'month')
                ? Object.assign({}, d, { itemStyle: { color: '#ee6666' } })
                : d
            );
        // console.log('year_data_usage: ', year_data_usage);

        const year_data_day = year_data.map(d => DateTime.fromJSDate(new Date(d.name)).monthShort);
        const year_data_temperature = year_data.map(d => ({ name: d.name, value: d.value.Temperature }));

        var option = {
            animation: false,
            tooltip: {
                position: 'top',
                formatter: (params) => (params && params.length === 2 && params[0].value)
                    ? DateTime.fromJSDate(new Date(params[0].name)).toLocaleString({ year: 'numeric', month: 'long' }) + '<br/> <b>' + params[0].value + ' kWh / ' + params[1].value + ' °C</b>'
                    : '',
                trigger: 'axis',
                axisPointer: {
                    type: 'none',
                }
            },
            visualMap: [{
                show: false,
                type: 'continuous',
                seriesIndex: 1,
                min: 5,
                max: 35,
                inRange: { color: ['#e0e0e0', '#1976d2', '#00E676', '#FFC107', '#F44336'] },
                outOfRange: { color: ['#B71C1C'] }
            }],
            xAxis: {
                type: 'category',
                data: year_data_day,
                axisTick: { show: false },
            },
            yAxis: [{
                // show: false
                type: 'value',
                splitLine: {
                    show: false
                },
                axisLabel: {
                    formatter: '{value} kWh',
                    fontSize: '0.6em',
                },
                min: 0,
            }, {
                type: 'value',
                splitLine: {
                    show: false
                },
                axisLabel: {
                    formatter: '{value} °C',
                    fontSize: '0.6em',
                },
                min: value => Math.min(0, Math.floor(value.min / 10) * 10),
                max: value => Math.max(30, Math.ceil(value.max / 10) * 10),
            }],
            grid: {
                left: '60pt',
                right: '40pt',
                top: '20%',
                bottom: '20%',
            },
            series: [{
                name: 'Usage',
                type: 'bar',
                barWidth: '80%',
                barGap: '0%',
                emphasis: {
                    itemStyle: {
                        shadowBlur: 5,
                    }
                },
                markLine: {
                    silent: true,
                    symbol: 'none',
                    label: {
                        show: false,
                    },
                    lineStyle: {
                        opacity: 0.5,
                    },
                    data: [
                        { yAxis: 500 },
                        { yAxis: 1000 },
                        { yAxis: 1500 },
                        { yAxis: 2000 },
                    ]
                },
                data: year_data_usage,
            }, {
                name: 'Temperature',
                type: 'line',
                yAxisIndex: 1,
                smooth: true,
                showSymbol: false,
                data: year_data_temperature,
            }]
        };

        return option
    }

    render() {
        const option = this.getOption(this.props);
        const { onChangeDate } = this.props;

        return (
            <ReactEcharts
                option={option}
                notMerge={false}
                lazyUpdate={false}
                style={{ height: '20vmax' }}
                onEvents={{ 'click': d => { onChangeDate(new Date(d.name)); } }}
            />
        );
    }
}

YearUsage.propTypes = {
    month: PropTypes.array,
    date: PropTypes.instanceOf(Date),
    onChangeDate: PropTypes.func,
};

YearUsage.defaultProps = {
    month: [],
    date: Date.now(),
    onChangeDate: () => { },
}

export default YearUsage;
