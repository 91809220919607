import { DateTime, Duration, Interval } from 'luxon';

function sliceByGranularity(values, date, granularity) {
    var duration

    switch (granularity) {
        case 'minute':
        case 'quarter':
        default:
            duration = 'day';
            break;
        case 'month':
            duration = 'month';
            break;
    }

    if (!values) {
        return values
    }

    return values
        .filter(d => DateTime.fromISO(d.Timestamp).hasSame(date, duration))
        .sort((a, b) => a.Timestamp - b.Timestamp);
}

function fillByGranularity(values, date, granularity) {
    if (!values) {
        return values
    }

    var duration
    var interval

    switch (granularity) {
        case 'minute':
            duration = 'day';
            interval = Duration.fromObject({ minute: 1 });
            break;
        case 'quarter':
        default:
            duration = 'day';
            interval = Duration.fromObject({ minutes: 15 });
            break;
        case 'day':
            duration = 'year';
            interval = Duration.fromObject({ day: 1 });
            break;
        case 'month':
            duration = 'year';
            interval = Duration.fromObject({ month: 1 });
            break;
    }

    date = DateTime.fromJSDate(date);
    const range = Interval.fromDateTimes(date.startOf(duration), date.endOf(duration))
    // console.log('fillByGranularity(', granularity, '): range:', date.startOf(duration).toISO(), '=>', date.endOf(duration).toISO())

    const values_map = new Map();
    values.filter(d => range.contains(new Date(d.Timestamp)))
        .forEach(d => values_map.set(DateTime.fromJSDate(new Date(d.Timestamp)).toISO(), d));
    // console.log('interval:', interval.toISO());

    const data = range
        .splitBy(interval)
        .map(d => d.start.toISO())
        .map(d => values_map.has(d) ? { name: d, value: values_map.get(d) } : { name: d, value: {} });

    // console.log(granularity, date.toISO(), data);

    return data;
}

function adjustPrecision(value, precisionUsage, precisionTemperature) {
    return Object.assign({}, value, {
        Usage: value.Usage ? parseFloat(value.Usage.toFixed(precisionUsage)) : undefined,
        Temperature: value.Temperature ? parseFloat(value.Temperature.toFixed(precisionTemperature)) : undefined,
    });
}

//  kWh => Watts
function adjustUsageFromEnergyToPower(value, granularity) {
    var t = 1;
    switch (granularity) {
        case 'quarter':
            t = 15.0 / 60.0;
            break;
        case 'minute':
            t = 1 / 60.0;
            break;
        case 'hour':
        default:
            t = 1;
            break;
    }

    return Object.assign({}, value, {
        Usage: value.Usage ? value.Usage * 1000.0 / t : undefined
    })
}

export { sliceByGranularity, fillByGranularity, adjustPrecision, adjustUsageFromEnergyToPower };
