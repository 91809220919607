import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactEcharts from 'echarts-for-react';
import { DateTime } from 'luxon';

import { fillByGranularity, adjustPrecision, adjustUsageFromEnergyToPower } from './utils';

function colorGradient(color1, color2, min, max) {
    const hex = x => Number(x).toString(16).padStart(2, '0');
    const nc = (c, i) => parseInt(c.substring(i * 2 + 1, (i + 1) * 2 + 1), 16);

    return function(value) {
        value = value < min ? min : value > max ? max : value;
        const ratio = (value - min) / (max - min);
        const r = Math.ceil(nc(color1, 0) * (1 - ratio) + nc(color2, 0) * ratio);
        const g = Math.ceil(nc(color1, 1) * (1 - ratio) + nc(color2, 1) * ratio);
        const b = Math.ceil(nc(color1, 2) * (1 - ratio) + nc(color2, 2) * ratio);
        const color = '#' + hex(r) + hex(g) + hex(b);
        return color;
    }
}

class DailyUsage extends Component {
    constructor(props) {
        super(props);
        this.state = {}
        this.grad = colorGradient('#9e9e9e', '#f5f5f5', 0, 3000);
        this.locale = window.navigator.languages.includes('zh') ? 'cn' : 'en';
    }

    getOption(props) {
        const { current, quarter, date } = props;

        if (!quarter) {
            return {}
        }

        // filter correct period data with filled gap
        const data_quarter = quarter ?
            fillByGranularity(quarter, date, 'quarter')
                //  display power (Watts) instead of energy for better understanding recent used appliances for time period less than an hour
                .map(d => ({ name: d.name, value: adjustUsageFromEnergyToPower(d.value, 'quarter') }))
                //  simplify the value by show integer in Watts
                .map(d => ({ name: d.name, value: adjustPrecision(d.value, 0, 1) }))
                : undefined;

        //  replace the 'undefined' value with 0 to generate the gap for the missing values
        const quarter_usage = data_quarter.map(d => ({ name: d.name, value: d.value.Usage ? d.value.Usage : 0, itemStyle: { color: this.grad(d.value.Usage), opacity: 0.5 } }))
        const tempColor = temp => temp < 20 ? '#bbdefb' : temp < 28 ? '#81c784' : '#f44336';
        // const tempColor = temp => temp < 20 ? colorGradient('#e0e0e0', '#81c784', 15, 20)(temp) : temp < 25 ? '#81c784' : colorGradient('#81c784', '#f44336', 25, 30)(temp)
        const quarter_temp = data_quarter.map(d => ({ name: d.name, value: d.value.Temperature ? d.value.Temperature : 0, itemStyle: { color: tempColor(d.value.Temperature), opacity: 0.7 } }))

        // console.log('quarter:', quarter);
        // console.log('data_quarter:', data_quarter);

        // Calculate max pie radius upper bound.
        const max_quarter_usage = Math.min(Math.max(...quarter_usage.map(d => d.value)), 4000);
        const radius_up = (((max_quarter_usage / 4000) * (0.75 - 0.25) + 0.25) * 100).toFixed(0) + '%';
        // console.log(max_quarter_usage, radius_up);

        const hour_name = Array.from({ length: 24 }, (e, i) => ({ value: 1, name: ' ' + i + ' ' }))
        // console.log(hour_name)

        const power_unit = this.locale === 'cn' ? '瓦' : 'W';

        var current_usage
        if (current) {
            // console.log(current)
            const power_color = current.Usage < 500 ? '#eeeeee' : current.Usage < 1500 ? '#f9a825' : '#c62828';
            const temp_color = current.Temperature < 20 ? '#bbdefb' : current.Temperature < 28 ? '#81c784' : '#f44336';

            current_usage = [
                { value: 1, name: current.Temperature + ' °C', label: { color: temp_color } },
                { value: 1, name: current.Usage + ' ' + power_unit, label: { color: power_color } },
            ]
        } else {
            current_usage = [{ value: 1, name: '' }, { value: 1, name: '' }]
        }

        var option = {
            series: [
                {
                    name: '实时信息',
                    type: 'pie',
                    radius: [0, '15%'],
                    center: ['50%', '50%'],
                    startAngle: 0,
                    color: 'transparent',
                    slient: true,
                    label: {
                        position: 'inside',
                        color: '#eeeeee',
                        fontSize: '1em',
                        fontFamily: 'monospace',
                        fontWeight: 'bold',
                    },
                    // emphasis: {
                    //     label: {
                    //         show: true,
                    //     }
                    // },
                    data: current_usage
                },
                {
                    name: '每刻温度',
                    type: 'pie',
                    radius: ['23%', '24.5%'],
                    center: ['50%', '50%'],
                    roseType: 'area',
                    startAngle: 270,
                    // color: '#fff59d',
                    color: '#9fa8da',
                    label: {
                        show: false,
                    },
                    itemStyle: {
                        borderRadius: 1
                    },
                    silent: true,
                    data: quarter_temp,
                },
                {
                    name: '每刻用电量',
                    type: 'pie',
                    radius: ['25%', radius_up],
                    center: ['50%', '50%'],
                    roseType: 'area',
                    startAngle: 270,
                    // color: '#fff59d',
                    color: '#9fa8da',
                    label: {
                        show: false,
                    },
                    itemStyle: {
                        borderRadius: 2
                    },
                    emphasis: {
                        label: {
                            show: true,
                            // lineHeight: 10,
                            // lineOverflow: 'truncate',
                            formatter: '{c} ' + power_unit,
                        },
                        itemStyle: {
                            color: '#e8eaf6',
                            opacity: 0.8,
                        }
                    },
                    // data: data_minute
                    data: quarter_usage
                    // data: data_hour
                },
                {
                    name: '峰谷时段',
                    type: 'pie',
                    radius: ['80%', '88%'],
                    center: ['50%', '50%'],
                    // roseType: 'radius',
                    slient: false,
                    label: false,
                    color: ['RoyalBlue', 'IndianRed', 'IndianRed', 'RoyalBlue'],
                    startAngle: 270,
                    itemStyle: {
                        borderRadius: 2,
                    },
                    emphasis: {
                        scale: false,
                    },
                    data: [6, 6, 6, 6]
                },
                {
                    name: '小时名',
                    type: 'pie',
                    radius: ['90%', '95%'],
                    center: ['50%', '50%'],
                    // roseType: 'radius',
                    slient: false,
                    label: {
                        // normal: {
                        formatter: ' {b} ',
                        rotate: 'tangential',
                        position: 'inside',
                        color: '#bdbdbd',
                        fontFamily: 'monospace',
                        fontWeight: 'bold',
                        // }
                    },
                    emphasis: {
                        scale: false,
                        // label: {
                        //     show: false
                        // }
                    },
                    startAngle: 278,
                    color: 'transparent',
                    data: hour_name
                }
            ]
        };

        return option
    }

    render() {
        // const { data } = this.state;
        const option = this.getOption(this.props);
        const { day, month, date, onChangeDate } = this.props

        // console.log(date)
        if (!date) {
            return '';
        }

        const dateMonth = DateTime.fromJSDate(date).toLocaleString({year: 'numeric', month: 'long'});
        const dateDay = DateTime.fromJSDate(date).toLocaleString({day: 'numeric'});

        var dateYesterday = new Date(date)
        dateYesterday.setDate(date.getDate() - 1)

        var dateTomorrow = new Date(date)
        dateTomorrow.setDate(date.getDate() + 1)

        var dayUsage
        var monthUsage
        if (day) {
            dayUsage = day
                .filter(d => DateTime.fromJSDate(new Date(d.Timestamp)).hasSame(date, 'day'))
                .map(d => d.Usage.toFixed(1))[0]
        }
        if (month) {
            monthUsage = month
                .filter(m => DateTime.fromJSDate(new Date(m.Timestamp)).hasSame(date, 'month'))
                .map(m => m.Usage.toFixed(0))[0]
        }

        return (
            <div className="content" style={{
                // height: 500,
                // backgroundImage: `linear-gradient(to bottom right, RoyalBlue, MidnightBlue)`
            }} >
                <div className="row">
                    <div className="col">
                        <ReactEcharts className="pt-5" style={{ maxHeight: '500pt', height: '80vmin' }} option={option} />
                    </div>
                </div>
                <div className="row py-3 px-2 font-weight-light" style={{ color: '#eee' }}>
                    <div>
                        <button type="button" className="btn btn-link" onClick={() => { onChangeDate(dateYesterday) }}>
                            <i className="bi bi-chevron-left"></i>
                        </button>
                    </div>
                    <div className="col">
                        {this.locale === 'cn' ? '今日' : 'today'}<br />
                        <div className="h6 font-weight-bold">
                            {dayUsage} kWh
                        </div>
                    </div>
                    <div className="col">
                        {dateMonth}<br />
                        <div className="h4 font-weight-bold">
                            {dateDay}
                        </div>
                    </div>
                    <div className="col">
                        {this.locale === 'cn' ? '本月' : 'this month'}<br />
                        <div className="h6 font-weight-bold">
                            {monthUsage} kWh
                        </div>
                    </div>
                    <div>
                        <button type="button" className="btn btn-link" onClick={() => { onChangeDate(dateTomorrow) }}>
                            <i className="bi bi-chevron-right"></i>
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

DailyUsage.propTypes = {
    current: PropTypes.object,
    quarter: PropTypes.array,
    day: PropTypes.array,
    month: PropTypes.array,
    date: PropTypes.instanceOf(Date),
    onChangeDate: PropTypes.func,
};

DailyUsage.defaultProps = {
    day: [],
    month: [],
    date: Date.now(),
    onChangeDate: () => { },
}

export default DailyUsage;
