import React, { Component } from 'react';
import './App.css';
import DailyUsage from './DailyUsage';
import MonthUsage from './MonthUsage';
import YearUsage from './YearUsage';
import YearUsageHeatmap from './YearUsageHeatmap';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date((new Date()).toDateString()),
    }

    this.base = process.env.REACT_APP_API_URL || window.location.href.replace(/\/$/, '');

    this.onChangeDate = (d) => {
      if (d < Date.now()) {
        this.setState({ date: new Date(d) })
      }
    }
    this.loadData = this.loadData.bind(this);
    this.loadWebSocket = this.loadWebSocket.bind(this);
  }

  loadData() {
    console.log('App.loadData()...')

    // fetch(this.base + "/api/data/minute")
    //   .then(res => res.json())
    //   .then(data => this.setState({ minute: data }))
    //   .catch(err => console.error)

    fetch(this.base + "/api/data/quarter")
      .then(res => res.json())
      .then(data => this.setState({ quarter: data }))
      .catch(err => console.error)

    // fetch(this.base + "/api/data/hour")
    //   .then(res => res.json())
    //   .then(data => this.setState({ hour: data }))
    //   .catch(err => console.error)

    fetch(this.base + "/api/data/day")
      .then(res => res.json())
      .then(data => this.setState({ day: data }))
      .catch(err => console.error)

    fetch(this.base + "/api/data/month")
      .then(res => res.json())
      .then(data => this.setState({ month: data }))
      .catch(err => console.error)
  }

  loadWebSocket() {
    const url = this.base.replace("http", "ws") + '/api/current'
    const ws = new WebSocket(url);

    ws.onmessage = (msg) => { this.setState({ current: JSON.parse(msg.data).Data }) }
    ws.onopen = () => console.log("WebSocket is connected to " + url);
    ws.onclose = () => { console.log("WebSocket is disconnected from " + url); setTimeout(() => this.loadWebSocket(), 1000); };

    this.ws = ws;
  }

  componentDidMount() {
    this.loadData()
    this.loadWebSocket()

    //  refresh the data every 5 minutes
    this.reloadInterval = setInterval(() => {
      this.loadData()
    }, 1000 * 60 * 5);
  }

  componentWillUnmount() {
    if (this.reloadInterval) {
      clearInterval(this.reloadInterval);
    }

    if (this.ws) {
      this.ws.close();
    }
  }

  render() {
    const { date, current, quarter, day, month } = this.state

    return (
      <div className="App container-fluid">
        <div className="row">
          <div className="col-12 col-md-6 center shadow-sm" style={{
            backgroundImage: `linear-gradient(to bottom right, RoyalBlue, MidnightBlue)`
          }}>
            <DailyUsage current={current} quarter={quarter} day={day} month={month} date={date} onChangeDate={this.onChangeDate} />
          </div>
          <div className="col-12 col-md-6">
            <div className="col-12">
              <MonthUsage day={day} date={date} onChangeDate={this.onChangeDate} />
            </div>
            <div className="col-12">
              <YearUsage month={month} date={date} onChangeDate={this.onChangeDate} />
            </div>
            <div className="col-12">
              <YearUsageHeatmap day={day} date={date} onChangeDate={this.onChangeDate} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default App;
